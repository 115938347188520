import * as React from "react"
import Layout from "../components/layout"

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Résumé</title>
        <meta name="description" content="Independent cartographer based in Grasslands National Park, Canada." />
        <meta name="image" content="https://awmcphee.ca/headshot.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/headshot.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="Alex McPhee"/>
        <meta name="twitter:description" content="Independent cartographer based in Grasslands National Park, Canada."/>
        <meta name="twitter:image" content="https://awmcphee.ca/headshot.jpg"/>
      </Helmet>
      <h1>Résumé</h1>
      <h2>Experience</h2>
      <p><strong>2021-date</strong> {"//"} Sole Proprietor {"//"} <strong>Prairie Heart Maps</strong></p>
        <ul><li>Extensive freelance GIS and self-publishing activities</li>
        <li>Specialist in prairie history</li></ul>
      <p><strong>2022-date</strong> {"//"} Digital Cartographer {"//"} <a
          className="plainlink"
          href="https://www.mainstreetresearch.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mainstreet Research</a></p>
        <ul><li>Editorial cartographer for iPolitics and QP Briefing</li>
        <li>Newsroom data analysis and visualization</li>
        <li>Graphics created for current events in Canada and the world</li></ul>
        <p><strong>2022-2023</strong> {"//"} Webmaster {"//"} <a
          className="plainlink"
          href="https://www.canadianpolling.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          Polling Canada</a></p>
        <ul><li>Developed the Polling Canada website from scratch</li>
        <li>Open public resource with best-in-class data visualization</li>
        <li>10,000+ hits a week</li></ul>
      <p><strong>2021</strong> {"//"} Federal Candidate {"//"} <a
          className="plainlink"
          href="https://eda.ndp.ca/donation/47002-Cypress_Hills--Grasslands-EN"
          target="_blank"
          rel="noopener noreferrer"
        >
          New Democratic Party
        </a></p>
      <ul>
        <li>
          First cartographer nominated by a Canadian political party
        </li>
        <li>Raised $34,000, earned Elections Canada rebate</li>
        <li>Supported other Sask. campaigns with maps and data</li>
      </ul>
      <p>
        <strong>2020-2022</strong> {"//"} Research Assistant {"//"} <a
          className="plainlink"
          href="https://www.futureenergysystems.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          Future Energy Systems
        </a>
      </p>
      <ul>
        <li>
          Created various scientific figures for publication
        </li>
        <li>
          Wildfire spread modeling, agriculture residue bio-energy, ecological
          citizen science...
        </li>
      </ul>
      <p>
        <strong>2019</strong> {"//"} Research Assistant {"//"} Univ. of Alberta{" "}
        <a
          className="plainlink"
          href="https://cms.eas.ualberta.ca/froeselab/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Froese Group
        </a>
      </p>
      <ul>
        <li>
          Mapped permafrost degradation in Northern Canada
        </li>
        <li>Two weeks of subarctic field experience in Sahtu region, NWT</li>
      </ul>
      <p>
        <strong>2018</strong> {"//"} Environmental Student {"//"} Seven Generations Energy
      </p>
      <ul>
        <li>
          Used enterprise GIS for cartography, environmental monitoring,
          Indigenous consultation
        </li>
      </ul>
      <h2>Published Work</h2>
      <p>
        See the rest of this website for more examples.
      </p>
      <p>
        <a className="plainlink" href="/alberta">
          <strong>McPhee's Alberta</strong>
        </a>
      </p>
      <ul>
        <li>Award-winning wall map</li>
      <li>
          <a
            className="plainlink"
            href="https://www.cartography.org.uk/2020-21-awards-entries"
            target="_blank"
            rel="noopener noreferrer"
          >
            Highly Commended, British Cartographic Society, 2020-21
          </a>
        </li>
        <li>
          <a
            className="plainlink"
            href="https://cartogis.org/docs/map_competition/map_design_competition2020.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Best Reference Map, CaGIS, 2020
          </a>
        </li>
        <li>
          <a
            className="plainlink"
            href="https://nacis.org/awards/2019-winner-mcphee/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Best in Design (Student), NACIS, 2019
          </a>
        </li>
        <li>Built using 100% free software and open data</li>
        <li>More than 400 copies sold</li>
      </ul>
      <p>
        <a className="plainlink" href="/saskatchewan">
          <strong>McPhee's Saskatchewan</strong>
        </a>
      </p>
      <ul><li>The even better sequel</li>
      <li>More than 1,400 copies sold!!</li></ul>
      <h2>Skills</h2>
      <p>
        <strong>Print maps</strong> {"//"} Multi-award-winning
      </p>
      <p>
        <strong>GIS</strong> {"//"} Thousands of hours experience in <a
          className="plainlink"
          href="https://qgis.org/en/site/"
          target="_blank"
          rel="noopener noreferrer"
        >QGIS</a>
      </p>
      <p>
        <strong>Data science</strong> {"//"} Experienced Python user
      </p>
      <p>
        <strong>Web fundamentals</strong> {"//"} Created this site from scratch
      </p>
      <p>
        <strong>Web interactives</strong> {"//"} Have published maps and dashboards
      </p>
      <p>
        <strong>Visual artist</strong> {"//"} Can work as a digital illustrator if necessary
      </p>
      <h2>Affiliations</h2>
      <p><strong>Chair</strong> {"//"} Val Marie Grain Elevator Committee</p>
      <p><strong>Chair</strong> {"//"} Sask. NDP Ag. & Rural Life Wing</p>
      <p><strong>Vice-President</strong> {"//"} Canadian Cartographic Association</p>
      <p><strong>Vice-President</strong> {"//"} Wood River NDP</p>
      <p><strong>Member-at-large</strong> {"//"} Cypress Hills—Grasslands NDP</p>
      <p><strong>Vice-President, Outreach & Events</strong> {"//"} Sask. NDP Rainbow Pride Wing</p>
      <p><strong>General Synod Delegate</strong> {"//"} Anglican Church of Canada, Diocese of Qu'Appelle</p>
      <p><strong>Provincial Synod Delegate</strong> {"//"} Anglican Church of Canada, Diocese of Qu'Appelle</p>
      <h2>Education</h2>
      <p>
      <strong>2020</strong> {"//"} University of Alberta {"//"} BSc Geophysics (Spec.)
      </p>
      <ul>
        <li>
          Won competitive Undergraduate Research Initiative Stipend ($5,000) for Sask. R.M. boundary reform project
        </li>
      </ul>
    </Layout>
  )
}